import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "QNAP Container Station & Kerberos",
  "path": "/Frequently_Asked_Question/QNAP_Container_Kerberus/",
  "dateChanged": "2022-02-02",
  "author": "Mike Polinowski",
  "excerpt": "Wie kann man die QNAP Container Station nutzen, um die Kerberos Kamera Software zu nutzen ?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='FAQs - QNAP Container Station & Kerberos' dateChanged='2022-02-02' author='Mike Polinowski' tag='INSTAR IP Camera' description='Wie kann man die QNAP Container Station nutzen, um die Kerberos Kamera Software zu nutzen ?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/QNAP_Container_Kerberus/' locationFR='/fr/Frequently_Asked_Question/QNAP_Container_Kerberus/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "qnap-container-station--kerberos-kamera-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#qnap-container-station--kerberos-kamera-software",
        "aria-label": "qnap container station  kerberos kamera software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`QNAP Container Station & Kerberos Kamera Software`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`:How to use QNAP Container Station to use `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Kerberos_with_Docker/"
      }}>{`Kerberos`}</a>{` ?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Newer QNAP NAS systems offer the possibility to run Docker containers. First, the Container Station must be installed and started on the QNAP. Docker is then also provided on the QNAP:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/efd07cfef75172d224702e4f0d8f63c7/2bef9/QNAP_Container_Station_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB0UlEQVQY0yWMz2sTQRiG988RwatepBZaqb96UCylCt6lPXjwIh5ab2LbmxRiEpuSJltNpYZiwRqktIiittiNJprYxDYbk2x2szs7883uzuzOiAk8PLzP5VX2PpYOtMqvI71ab/6o6MWyXiw3tFJj4D56saRrA8rNw5J+UPzz5fvJ18OaEs8eLeVPFnL1pxvH2m+bAMXAAiYCJhiXAReD3U/BQun5IcLURoSAr0w93Jt6sDt5f+fy3cLWTtV1eq12F8BjLKKE+l4QhpKziLOIEAqEchZhDB74nEXK9Oy7mbnC9KPC5L3N7d1KwHC7YxIacB59q9Rapu0iQvvZsez6XyPkomOYP4913+fK6O3M2J3syK30mbFn6280KSOEgHHBudj+tF/T20bH7HZ7gc9blt20bAC/1nM/V+s9RJTRidjIROzC9aXTQ/O5/L6UArngugDgEwxCyCiSYShDLgn4mHgikjqChuWEoVCGry0OXZk/f+nJqbOz6voH5FiGYSGEMYGuaTkOph5z/98RjAETihAGDEAoJlS5emNh/ObixfHH54bn0ur715tbyZWXqXQulszEEpnE8loytbaymkuk1NRqLv5cTWdfJVMv4svqRv7tP5X4h0tQI1WoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efd07cfef75172d224702e4f0d8f63c7/e4706/QNAP_Container_Station_01.avif 230w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/d1af7/QNAP_Container_Station_01.avif 460w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/7f308/QNAP_Container_Station_01.avif 920w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/e3723/QNAP_Container_Station_01.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/efd07cfef75172d224702e4f0d8f63c7/a0b58/QNAP_Container_Station_01.webp 230w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/bc10c/QNAP_Container_Station_01.webp 460w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/966d8/QNAP_Container_Station_01.webp 920w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/a9a89/QNAP_Container_Station_01.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efd07cfef75172d224702e4f0d8f63c7/81c8e/QNAP_Container_Station_01.png 230w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/08a84/QNAP_Container_Station_01.png 460w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/c0255/QNAP_Container_Station_01.png 920w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/2bef9/QNAP_Container_Station_01.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/efd07cfef75172d224702e4f0d8f63c7/c0255/QNAP_Container_Station_01.png",
              "alt": "QNAP Container Station & Kerberos",
              "title": "QNAP Container Station & Kerberos",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To use Docker, the `}<strong parentName="p">{`SSH`}</strong>{` or `}<strong parentName="p">{`Telnet`}</strong>{` access to the QNAP must be set up first - this is only needed to download the Docker images and can be `}<strong parentName="p">{`closed again`}</strong>{` afterwards:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/0940f/QNAP_Container_Station_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABGElEQVQY04XNv0rDUBTH8byYCB3UaDRWyB8JQaWoY8En8BUcOgjiIEhx9THEQWgawVoaxKI0yU3a3nPuSW+aSCsUhxa/fIaz/DhKy+u2/eCt0+/2vj0/8PzeH8FCqz3nz71+vPjB07OvXDXuGtf3N7fN5sMjS4EmBZAU2VRkU6QcfglJk4JkuYCUjzgpa+vqllqtVLZ13frsfyXDURiziKURS5PhEBEQgEiUy1IODPf4tG46Z7Xzi0EYA4ox52MOHBAQiUgIURTF8rFm1Q3D2dwx7MNa1HknIgDOOQcAxNklpSxXpKjupWm5+r5tmkfxIEzTNEmSLMuklHmeSylXvZ2NNzRrr+pou4Ztn0RRTERhGDLGELH8rx8PuGbWgN3C1AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/e4706/QNAP_Container_Station_02.avif 230w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/d1af7/QNAP_Container_Station_02.avif 460w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/7f308/QNAP_Container_Station_02.avif 920w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/67819/QNAP_Container_Station_02.avif 1154w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/a0b58/QNAP_Container_Station_02.webp 230w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/bc10c/QNAP_Container_Station_02.webp 460w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/966d8/QNAP_Container_Station_02.webp 920w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/e3efe/QNAP_Container_Station_02.webp 1154w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/81c8e/QNAP_Container_Station_02.png 230w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/08a84/QNAP_Container_Station_02.png 460w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/c0255/QNAP_Container_Station_02.png 920w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/0940f/QNAP_Container_Station_02.png 1154w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/c0255/QNAP_Container_Station_02.png",
              "alt": "QNAP Container Station & Kerberos",
              "title": "QNAP Container Station & Kerberos",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now you can connect to the QNAP via SSH or Telnet and create the required folders for the desired container:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`ssh`}</span>{` myuser@`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`NAS_IP_ADDRESS`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p kerberos/`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`config,capture,logs,webconfig`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now briefly check if Docker was installed successfully:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`instar@NAS587415 ~`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`$ docker version
Client:
 Version:           `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20.10`}</span>{`.7-qnap3
 API version:       `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.41`}</span>{`
 Go version:        go1.13.15
 Git commit:        50b64c4
 Built:             Tue Oct `}<span parentName="code" {...{
            "className": "token number"
          }}>{`26`}</span>{` 07:03:45 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2021`}</span>{`
 OS/Arch:           linux/amd64
 Context:           default
 Experimental:      `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span></code></pre></div>
    <p>{`And already you can download a desired Docker image - e.g. `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Kerberos_with_Docker/"
      }}>{`Kerberos`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`instar@NAS587415 ~`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`$ docker pull kerberos/kerberos
Using default tag: latest
latest: Pulling from kerberos/kerberosDigest: sha256:dba902db8d1ba63dbd7b6ce3653c8b4e6ba482d47a0c72d3f0d0d823e44ce68e
Status: Downloaded newer image `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` kerberos/kerberos:latest
docker.io/kerberos/kerberos:latest`}</code></pre></div>
    <p>{`Back on the QNAP web interface, you can now see that the container image is ready:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/223ed300874a9a157e29b5acb34e2f9f/84bf8/QNAP_Container_Station_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABe0lEQVQoz2M4de7hyStPL959c/Ph2wdPPzx6+uHBk/dY0cOnHx4+/fDg2cfbD99evfvy1t0XDE1TrrTMu1kx/WrfslsfPn75/fvXf4Lg379/f//9//+fwTVtm0f6DqvoTSGFe+49eHbnzv379x8/fvL87r1HDx89f/b89dNnr54+ew1BL16+vf/gyc1bdx8+evb8+SsG76TVPsmrbcOXhuduOH7q4sYdezdt3b12/dbN2/Zs2b5n2859GzbvWL9px8YtOzdv3b156+6NW3Zu2b5n3abtBw+fYHAOne0cNtvMa6pf3LwPH79AHPWfGPDvP4Ola6ejT5+Fa6dHyITXbz7+/vX3+/dfP378/v4diiBsdPLH72/ffzGY29eb29cZmFW6+bS+efvx79//P3/++fXrL0H08+cfhqT06fFJk0IjetIyp75794k0zWaG6Vammdpq8Q62eW/ekGizhnSArmKIgrCniWbkmzcf/vz5/+PH758//xBEP378BgCq9vZtWMm+eAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/223ed300874a9a157e29b5acb34e2f9f/e4706/QNAP_Container_Station_03.avif 230w", "/en/static/223ed300874a9a157e29b5acb34e2f9f/d1af7/QNAP_Container_Station_03.avif 460w", "/en/static/223ed300874a9a157e29b5acb34e2f9f/7f308/QNAP_Container_Station_03.avif 920w", "/en/static/223ed300874a9a157e29b5acb34e2f9f/c0f7c/QNAP_Container_Station_03.avif 1162w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/223ed300874a9a157e29b5acb34e2f9f/a0b58/QNAP_Container_Station_03.webp 230w", "/en/static/223ed300874a9a157e29b5acb34e2f9f/bc10c/QNAP_Container_Station_03.webp 460w", "/en/static/223ed300874a9a157e29b5acb34e2f9f/966d8/QNAP_Container_Station_03.webp 920w", "/en/static/223ed300874a9a157e29b5acb34e2f9f/4f5bc/QNAP_Container_Station_03.webp 1162w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/223ed300874a9a157e29b5acb34e2f9f/81c8e/QNAP_Container_Station_03.png 230w", "/en/static/223ed300874a9a157e29b5acb34e2f9f/08a84/QNAP_Container_Station_03.png 460w", "/en/static/223ed300874a9a157e29b5acb34e2f9f/c0255/QNAP_Container_Station_03.png 920w", "/en/static/223ed300874a9a157e29b5acb34e2f9f/84bf8/QNAP_Container_Station_03.png 1162w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/223ed300874a9a157e29b5acb34e2f9f/c0255/QNAP_Container_Station_03.png",
              "alt": "QNAP Container Station & Kerberos",
              "title": "QNAP Container Station & Kerberos",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Clicking the `}<strong parentName="p">{`+`}</strong>{` icon allows you to start a container instance:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6390b6b7896523a3e6166fb15431a1ef/6be49/QNAP_Container_Station_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABt0lEQVQoz22RzUsUcRzG5w/w1knQQ4IQGB6EAhcRosTwIBbhIRACL75FHQr2lHgqFA/78puZxewQtaCHoPor9KSIY8u0eqjVw8z83n/zexl3p3aNVdMPD9/b8zw8fC2nVP60+b12fHJYPapWj4IgopQTwq4KY8a5rB7+Km9++7jx9XP5i/VgIju1sAIRppQGQYAxVkqZ69Bap2n60/fzuVyxWATAtnoz8/efLEKIjTGYCyGElFIprZT+DyllmqY7O7tvFpfevlteXlm1bt2dHnmcDYKQcx5FkRBCa61ayMsIIdK0sbW1PTP7fG7+xavXWaun/+m9sZdhCMOoeTBCuAVjrJ3Szmo0GgfegeuW1tc/rK29t7p7x4dHZhAkUKA9WoljJeI4bvVfu3l/38vlCgC4ADhW182Hg8PPwiAilPw4wXu/cS0kGCMRS6n0mS6aPc/L54uOU7Jt17ozODn+aC4Mm2sjzCDhmDZFuaA8Jqx5tTFJct58bu7qzAxlJmu1YwghIcRofZokp8kljDHtzZVKpVAA/8w3Om4P9I0yxuv1+tlL1BWklBBChBCl1Pd9ABzbdv+a/wBkLvDqRp3JCwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6390b6b7896523a3e6166fb15431a1ef/e4706/QNAP_Container_Station_04.avif 230w", "/en/static/6390b6b7896523a3e6166fb15431a1ef/d1af7/QNAP_Container_Station_04.avif 460w", "/en/static/6390b6b7896523a3e6166fb15431a1ef/7f308/QNAP_Container_Station_04.avif 920w", "/en/static/6390b6b7896523a3e6166fb15431a1ef/e35d4/QNAP_Container_Station_04.avif 1160w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6390b6b7896523a3e6166fb15431a1ef/a0b58/QNAP_Container_Station_04.webp 230w", "/en/static/6390b6b7896523a3e6166fb15431a1ef/bc10c/QNAP_Container_Station_04.webp 460w", "/en/static/6390b6b7896523a3e6166fb15431a1ef/966d8/QNAP_Container_Station_04.webp 920w", "/en/static/6390b6b7896523a3e6166fb15431a1ef/26598/QNAP_Container_Station_04.webp 1160w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6390b6b7896523a3e6166fb15431a1ef/81c8e/QNAP_Container_Station_04.png 230w", "/en/static/6390b6b7896523a3e6166fb15431a1ef/08a84/QNAP_Container_Station_04.png 460w", "/en/static/6390b6b7896523a3e6166fb15431a1ef/c0255/QNAP_Container_Station_04.png 920w", "/en/static/6390b6b7896523a3e6166fb15431a1ef/6be49/QNAP_Container_Station_04.png 1160w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6390b6b7896523a3e6166fb15431a1ef/c0255/QNAP_Container_Station_04.png",
              "alt": "QNAP Container Station & Kerberos",
              "title": "QNAP Container Station & Kerberos",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the advanced settings you can specify the desired ports here - in the example below I set the `}<strong parentName="p">{`Web Port`}</strong>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8888`}</code>{` and the `}<strong parentName="p">{`RTSP Streaming Port`}</strong>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8889`}</code>{`. (see `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Kerberos_with_Docker/"
      }}>{`Kerberos Guide`}</a>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5c5a981cebd588a377464b38947c080b/cdef6/QNAP_Container_Station_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB3ElEQVQoz3WRzWsTURDA9+LJkyC9KBQP4k0FC/4DevAgHhSk4AdYKYLa1uIhUCFU8ANRYpruprUtVjwULx6UoiaxJtTQxlqxTbK72SYkPYak2Zf9nPdedkdMoEVafwzDMMwPhhnheWjm/cf4Vn2rUtkslyu63rQs2zSt3WEYpuuCohZnZudev303+2ZOOH12aDg4bZmkWq02Gg1KKedsz2CMImI+l3sZCkmSJIqS0H2q/8ZQhIJdq9UIIa7rUkphLxzHQcRM5kcgMDL68NGTp8+EIyeu9d19wRmYpkUIqdfrhJBms2nvwrIsCpBOLw0MDg/cuz/yICgcOnbp+q3H6HPDMNoTtuWAA9QG6lBGeQsR0ffbyUPEbDYnidGpqenJiVdCV/e5q31BRHQc5/d6/sN8LJZIxRKphWQ6Fk9+jie/fvueWFj8Ek/Of0qkFpeWlzPhcESSJkQxKnQdPtN7JYCIjPFyZTObU7SNkqxqslIobJRUrZhXCoqqqYWirGjFUnll5Wc4HIlGJ//Kx09euH1nFBEBgDHqe157Q7/FObbxPG+7iYirq7925IMHenovDyKi6wL9B7ZdAEDnYJy31tbWd+T9+45ePH+zI7P/AAC6rndeIMvK2Nh4R/4D+9HpwtCP2n8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5c5a981cebd588a377464b38947c080b/e4706/QNAP_Container_Station_05.avif 230w", "/en/static/5c5a981cebd588a377464b38947c080b/d1af7/QNAP_Container_Station_05.avif 460w", "/en/static/5c5a981cebd588a377464b38947c080b/7f308/QNAP_Container_Station_05.avif 920w", "/en/static/5c5a981cebd588a377464b38947c080b/63701/QNAP_Container_Station_05.avif 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5c5a981cebd588a377464b38947c080b/a0b58/QNAP_Container_Station_05.webp 230w", "/en/static/5c5a981cebd588a377464b38947c080b/bc10c/QNAP_Container_Station_05.webp 460w", "/en/static/5c5a981cebd588a377464b38947c080b/966d8/QNAP_Container_Station_05.webp 920w", "/en/static/5c5a981cebd588a377464b38947c080b/5231b/QNAP_Container_Station_05.webp 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5c5a981cebd588a377464b38947c080b/81c8e/QNAP_Container_Station_05.png 230w", "/en/static/5c5a981cebd588a377464b38947c080b/08a84/QNAP_Container_Station_05.png 460w", "/en/static/5c5a981cebd588a377464b38947c080b/c0255/QNAP_Container_Station_05.png 920w", "/en/static/5c5a981cebd588a377464b38947c080b/cdef6/QNAP_Container_Station_05.png 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5c5a981cebd588a377464b38947c080b/c0255/QNAP_Container_Station_05.png",
              "alt": "QNAP Container Station & Kerberos",
              "title": "QNAP Container Station & Kerberos",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you don't create volumes in the following step, all data - that is all recorded videos, but also the software settings - will always be deleted when the container of the software is "destroyed". If this is not a problem, you can skip this step.`}</p>
    <p>{`As storage location I take the folders created before via SSH. Attention, if no data is created there during container operation, you may have to check the LINUX permissions for the selected location:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/732351c28ce18cbc0bac7a60c53b007b/cdef6/QNAP_Container_Station_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACFklEQVQoz3WRzWvTcBjHc/HoTZiXgXoQjyoM/AOGh110MBGmiAdfBk5l3iwbelCGYseq64tWpoKHCVNxY9OiS9u0eW/efk1n1zRN0h6W2m7JbLclXZtIV9jwsC8Pz+Xhw/fL94G8vulPXxd1vSzLiiTldf3P2ppRrRrV6vru3p9KZd00a0DMht/PTH+cffdhBjp3/sGwJ1StlEulkmkYjuO4B6hzAgBMeCcC/rag4z23B4e8DXvbMExFLXJChhVElhdTHBDSyzzIMBxgWMByaYJixUw2kUx6PGNPno4/e/4COnHm2pVb480d27LtlXTmx5e575/nFme/IRF4CUbmFyLzC5FoHIvGsV9wgqS5WCx+Z/j+3Xsjo2OPoO5Tly5ff2xZW+bGRtM066pSL2qbRc2t1+q1v2V9dWuzvhfbcVpAEKam/OHw21DoDXT0WN/A4EPXdRuNRnlVl7K5/IokZXNyLi8X1JwkywVVUYuKWpRkRdNKBEFOTr4MBEJ+fxDq6u69ODCyC+9kc3ICoxCUhBEsmsCTGBVDMDiOwnE0iqA/lxAyxeE44fO9CgZft+HTZ/tv3BztOGtaiRdEIP5m2HZJNMNTKa5dGAc4XqRoDojL/8FdR3r6Lwx1YLmgkjSXYgFBsQTFojidxCiS5nCSQXEaSRI8yNApZh8+fOhkX+9V13Uty3Kc1kE/tqxt27ZazaYoinvwP5Hx0EyqnCDRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/732351c28ce18cbc0bac7a60c53b007b/e4706/QNAP_Container_Station_06.avif 230w", "/en/static/732351c28ce18cbc0bac7a60c53b007b/d1af7/QNAP_Container_Station_06.avif 460w", "/en/static/732351c28ce18cbc0bac7a60c53b007b/7f308/QNAP_Container_Station_06.avif 920w", "/en/static/732351c28ce18cbc0bac7a60c53b007b/63701/QNAP_Container_Station_06.avif 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/732351c28ce18cbc0bac7a60c53b007b/a0b58/QNAP_Container_Station_06.webp 230w", "/en/static/732351c28ce18cbc0bac7a60c53b007b/bc10c/QNAP_Container_Station_06.webp 460w", "/en/static/732351c28ce18cbc0bac7a60c53b007b/966d8/QNAP_Container_Station_06.webp 920w", "/en/static/732351c28ce18cbc0bac7a60c53b007b/5231b/QNAP_Container_Station_06.webp 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/732351c28ce18cbc0bac7a60c53b007b/81c8e/QNAP_Container_Station_06.png 230w", "/en/static/732351c28ce18cbc0bac7a60c53b007b/08a84/QNAP_Container_Station_06.png 460w", "/en/static/732351c28ce18cbc0bac7a60c53b007b/c0255/QNAP_Container_Station_06.png 920w", "/en/static/732351c28ce18cbc0bac7a60c53b007b/cdef6/QNAP_Container_Station_06.png 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/732351c28ce18cbc0bac7a60c53b007b/c0255/QNAP_Container_Station_06.png",
              "alt": "QNAP Container Station & Kerberos",
              "title": "QNAP Container Station & Kerberos",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now you can start the container and a short time later you will see it listed in the container overview - here you can also stop or restart the container:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a2e39606de65f1fc7681755ccc256ce9/d74fe/QNAP_Container_Station_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABnUlEQVQoz5XO3StDcRwG8PO3uOFCopyVZQlN8lIoS9G8hlyIUkpJaRSKK0oprnbjgpRIKY0zcxxvh52zs7PZy4VmZ/bCcF5+L5zJS2nKt8/F87146iFcTOD4KnThvef8UTEo3YYf/KGYLyT5gzHfT6HPEI7zt1FWjPDeO8K25J5aFcaWuXm7GE+mU6mUpgEAEAAQQvQFQYwghjqkKqr8KgMACXPvTnXfrrFtq2FwL3KfSD+ln19kVdVeX2T8+1DGR0SYqO1ar+tZL2uxNw9snF1yjiMXQ59TTnr/gDo4PHEc0dTxWQZz6Dx1UCeUi9EzRZ+es0SlZaXSsmKoWay3rvFCgBeFTcaz7bihafaCvXbzXjcncB7R4/VzHlF/dcIl6xZ9QaKkara8fqGkas7cuBBPPGKMnwBS4fe8bKfPLjaNk6bxAnK0onoyKiUBwJoCNBWqGYoCsoCyrBHFhiHSMJyX11dqGpGkBIRYUYD6Wf6DogCiKL+bLOzNzWk1kv1SNIHQf8od1pn2VpulaaLTOh2LJQFAsqxlX/vtffYbOgj18DYEs/kAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2e39606de65f1fc7681755ccc256ce9/e4706/QNAP_Container_Station_07.avif 230w", "/en/static/a2e39606de65f1fc7681755ccc256ce9/d1af7/QNAP_Container_Station_07.avif 460w", "/en/static/a2e39606de65f1fc7681755ccc256ce9/7f308/QNAP_Container_Station_07.avif 920w", "/en/static/a2e39606de65f1fc7681755ccc256ce9/db27b/QNAP_Container_Station_07.avif 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a2e39606de65f1fc7681755ccc256ce9/a0b58/QNAP_Container_Station_07.webp 230w", "/en/static/a2e39606de65f1fc7681755ccc256ce9/bc10c/QNAP_Container_Station_07.webp 460w", "/en/static/a2e39606de65f1fc7681755ccc256ce9/966d8/QNAP_Container_Station_07.webp 920w", "/en/static/a2e39606de65f1fc7681755ccc256ce9/4b3db/QNAP_Container_Station_07.webp 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2e39606de65f1fc7681755ccc256ce9/81c8e/QNAP_Container_Station_07.png 230w", "/en/static/a2e39606de65f1fc7681755ccc256ce9/08a84/QNAP_Container_Station_07.png 460w", "/en/static/a2e39606de65f1fc7681755ccc256ce9/c0255/QNAP_Container_Station_07.png 920w", "/en/static/a2e39606de65f1fc7681755ccc256ce9/d74fe/QNAP_Container_Station_07.png 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a2e39606de65f1fc7681755ccc256ce9/c0255/QNAP_Container_Station_07.png",
              "alt": "QNAP Container Station & Kerberos",
              "title": "QNAP Container Station & Kerberos",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can get the same info via the SSH connection:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{`
IMAGE                      STATUS          PORTS                                          NAMES
kerberos/kerberos:latest   Up `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{` seconds   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:8889-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8889`}</span>{`/tcp, `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:8888-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{`/tcp   kerberos`}</code></pre></div>
    <p>{`The web interface of the software can now be accessed in the browser via its web port on the IP of the QNAP:`}</p>
    <blockquote>
      <p parentName="blockquote"><code parentName="p" {...{
          "className": "language-text"
        }}>{`http://{NAS_IP_ADDRESS}:8888/welcome`}</code></p>
    </blockquote>
    <p>{`For further setup of the Kerberos camera software `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Kerberos_with_Docker/"
      }}>{`please see the Kerberos manual`}</a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      